import Element from '../../domain/Element/Element'
import Block from '../../domain/Block/Block';

/**
 * Check if Condition is Required
 * @param element
 * @param validationState
 */
const isRequiredCondition = (
    element: Element,
    validationState: boolean
): boolean => {
    if (element.condition && (validationState && false === element.condition.actions.required)) {
        return false
    }

    if (element.condition && (validationState && element.condition.actions.required)) {
        return true
    }

    return element.attributes?.required ?? false
}

/**
 * Check if Condition is Disabled
 * @param element
 * @param validationState
 */
const isDisabledCondition = (
    element: Element,
    validationState: boolean
): boolean => {

    // 👇️ Check if NOT undefined or null (using loose not equals)
    if (undefined == element.condition || undefined == element.condition.actions.disabled) {
        return element.attributes?.disabled ?? false
    }

    if (validationState) {
        return element.condition.actions.disabled
    }

    return element.attributes?.disabled ?? false
}

/**
 * Check if Condition is Visible
 * @param element
 * @param validationState
 */
const isVisibleCondition = (
    element: Element,
    validationState: boolean
): boolean => {
    return !(
        // 👇️ Check if NOT undefined or null (using loose not equals)
        undefined != element.condition
        && (
            (validationState && false === element.condition.actions.visible)
            || (!validationState && element.condition.actions.visible)
        )
    );
}

/**
 * Check if Condition is Visible in Block
 * @param block
 * @param validationState
 */
const isVisibleConditionBlock = (
    block: Block | null,
    validationState: boolean
): boolean => {
    // 👇️ Check if NOT undefined or null (using loose not equals)
    if (undefined == block) {
        return true
    }
    if (null === block.condition && block.visible === false) {
        return false
    }
    return !(
        // 👇️ Check if NOT undefined or null (using loose not equals)
        undefined != block.condition
        && (
            (validationState && false === block.condition.actions.visible)
            || (!validationState && block.condition.actions.visible)
        )
    );
}

export {isRequiredCondition, isDisabledCondition, isVisibleCondition, isVisibleConditionBlock}
