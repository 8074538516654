import FormGatewayInterface from './FormGatewayInterface';
import FolderGatewayInterface from '../Folder/FolderGatewayInterface';

export default class PostUseCase
{
    public formGateway;
    public folderGateway;

    constructor(FormGatewayInterface: FormGatewayInterface, FolderGateway: FolderGatewayInterface) {
        this.formGateway = FormGatewayInterface;
        this.folderGateway = FolderGateway;
    }

    execute(data: Array<string>, blockId: string, productCode: string): Promise<unknown> {
        return this.folderGateway.updateFolder(data, blockId, productCode).then((response) => {
            this.formGateway.updateState(response.status)
            const values = Object.assign(data, response.values.filter((e) => e.value !== '').reduce((a, v) => ({...a, [v['element_id']]: v['value']}), {}))
            return this.formGateway.save(values)
        })
    }
}
